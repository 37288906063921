require("jquery")

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery'
global.$ = $;
global.jQuery = $;

document.addEventListener("DOMContentLoaded", function(){
  window.addEventListener('scroll', function() {
    if (window.scrollY > 50) {
      if (document.getElementById('navbar_top')) {
        document.getElementById('navbar_top').classList.add('fixed-menu');
      }
    } else {
      if (document.getElementById('navbar_top')) {
        document.getElementById('navbar_top').classList.remove('fixed-menu');
      }
    }
  });
});

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
